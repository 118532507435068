.box{
    width: 100%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    background-image: url('../../images/10.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-transform: uppercase;
    z-index: -2;
}

.box > div{
    height: 3px;
    background-color: gold;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.box > h2{
    font-size: 40px;
}

.box > h3{
    font-size: 35px;
}

.box::after{
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#00000025, #000000c7);
    content: '';
    top: 0;
    z-index: -1;
}