.wrapper{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Source Sans Pro', sans-serif;

}

.wrapper > h3 {
    width: 90%;
    text-transform: uppercase;
    margin-bottom: 25px;
    position: relative;
}

.wrapper > h3::after{
    width: 20px;
    height: 2px;
    background-color: gold;
    position: absolute;
    content: '';
    top: 25px;
    left: 0;
}

.box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.desc{
    margin-bottom: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.space{
    width: 30%;
    height: 3px;
    background-color: gold;
    margin-top: 5px;
}

.supp{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.supp > h2{
    width: 30%;
    font-size: 150px;
    color: #747474;
}

.supp > h3{
    padding: 0 30px;
    text-align: center;
    width: 90%;
}

.bank{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.bank > div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.bank > div > .border{
    min-width: 3px;
    height: 100px;
    background-color: gold;
    border-radius: 5px;
    width: 0;
}

.bank p{
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747474;
}

.box2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

.box2 > h3{
    width: 200px;
    text-align: center;
}

@media(max-width: 1100px){
    .bank p {
        width: 220px;
    }
}

@media(max-width : 700px){
    .bank > div{
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: center;
        align-items: center;
    }
}