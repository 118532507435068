.card{
    width: 320px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon{
    font-size: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747474;
}

.space{
    width: 70%;
    height: 3px;
    border-radius: 5px;
    background-color: gold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.card > h3{
    width: 90%;
    text-align: center;
    font-size: 25px;
}

.card > p {
    width: 90%;
    height: 100px;
    text-align: center;
}
